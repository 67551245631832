import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  logoText = 'Online Test Server';
  mobilenumber = '';
  domainname = '';
  isEng = true;


/////
// public menuSubject: Subject<boolean> = new BehaviorSubject<boolean>(true);
// public menuActive = this.menuSubject.asObservable();
// /////

public langSource: Subject<boolean> = new BehaviorSubject<boolean>(true);
public currentLang = this.langSource.asObservable();

onChangeLang(val: boolean) {
  this.langSource.next(val);
}

/////
// toggleMenu(val: boolean) {
//   this.menuSubject.next(val);
// }
/////

  // private langSource = new BehaviorSubject<boolean>(this.isEng);
  // currentLang = this.langSource.asObservable();

  // private messageSource = new BehaviorSubject<string>('false');
  // currentMessage = this.messageSource.asObservable();

  // changeMessage(message: string) {
  //   this.messageSource.next(message);
  // }

  // changeLang(lang: boolean) {
  //   this.langSource.next(lang);
  //   console.log(this.langSource.next(lang));
  // }



}
