import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
})
export class LogoComponent implements OnInit {
  logoText: string;

  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    this.logoText = this.sharedService.logoText;
  }

}
