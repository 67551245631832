import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared/shared.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  isEng: boolean;
  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    this.sharedService.currentLang.subscribe(x => this.isEng = x);
  }

}
