import { Component, OnInit, HostListener, Inject, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { SharedService } from '../shared/shared.service';
// import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fade',
      [
        state('void', style({ opacity: 0 })),
        transition(':enter', [animate(300)]),
        transition(':leave', [animate(500)]),
      ]
    )]
})
export class HomeComponent implements OnInit {
  innerPageHeight: number;
  innerPageWidth: number;
  public isEng = true;
  public localBool = true;

  heroWrap: number;
  boxWrap: number;

  serviceOne = false;
  serviceTwo = false;
  serviceThree = false;
  serviceFour = false;
  serviceFive = false;
  serviceSix = false;

  // tslint:disable-next-line:variable-name
  @ViewChild('fullpageRef', {static: false}) fp_directive: ElementRef;
  config;
  // tslint:disable-next-line:variable-name
  fullpage_api;

  constructor(private renderer: Renderer2, private sharedService: SharedService) {
    // this is just an example => for more details on config please visit fullPage.js docs
    this.config = {
      // licenseKey: '1222786',
       licenseKey: 'YOUR LICENSE KEY HERE',
      anchors: ['home', 'serviceOne', 'serviceTwo', 'serviceThree', 'serviceFour', 'serviceFive', 'serviceSix', 'lastPage'],
      menu: '#menu',
      navigation: true,
      sectionsColor: ['#414544', '#f06292', '#ff7043', '#BA68C8', '#AED581', '#81c784', '#4fc3f7', '#03a9f4'],
      // loopHorizontal: false,
      // normalScrollElements: '.scrollable-content',
      // events callback
      afterLoad: (origin, destination, direction) => {
        // console.log(destination);
      },
      afterRender: () => {
        // console.log('afterRender');
      },
      afterResize: (width, height) => {
        // console.log('afterResize' + width + ' ' + height);
      },
      afterSlideLoad: (section, origin, destination, direction) => {
        // console.log(destination);
      }
    };
  }

  ngOnInit() {
    this.innerPageHeight = window.innerHeight;
    this.innerPageWidth = window.innerWidth;
    this.sharedService.currentLang.subscribe(x => this.isEng = x);

    this.heroWrap = this.innerPageHeight / 10;
    this.boxWrap = this.innerPageHeight / 10;

    this.manageGap();

    console.log('ngOnInit: ' + this.innerPageHeight);
  }

  private manageGap() {
    if (this.innerPageWidth > 1366 && this.innerPageHeight > 768) {
    }
    if (this.innerPageWidth > 1400 && this.innerPageHeight > 899) {
      this.heroWrap = this.innerPageHeight / 8;
      this.boxWrap = this.innerPageHeight / 6;
    }
    if (this.innerPageWidth > 319 && this.innerPageWidth < 375 && this.innerPageHeight > 567 && this.innerPageHeight < 667) {
      this.heroWrap = this.innerPageHeight / 100;
      this.boxWrap = this.innerPageHeight / 50;
    }
    if (this.innerPageWidth > 374 && this.innerPageHeight > 1023 && this.innerPageHeight < 1100) {
      this.heroWrap = this.innerPageHeight / 10;
      this.boxWrap = this.innerPageHeight / 10;
    }
    if (this.innerPageWidth > 767 && this.innerPageHeight > 666 && this.innerPageHeight < 900) {
      this.heroWrap = this.innerPageHeight / 20;
      this.boxWrap = this.innerPageHeight / 10;
    }
    
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // console.log(this.innerPageHeight);
   // this.innerPageHeight < 992 ? this.innerPageHeight = window.innerHeight : this.innerPageHeight = 1000;
    this.innerPageHeight = window.innerHeight;
    this.innerPageWidth = window.innerWidth;
   // console.log('innerPageHeight: ' + this.innerPageHeight);
    this.manageGap();
  }

  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
  }

  addSection() {
    // change background color
    // tslint:disable-next-line:no-string-literal
    this.config['sectionsColor'] = Array(6).fill(0).map(x => this.randomColor());

    // creating the section div
    const section = this.renderer.createElement('div');
    this.renderer.addClass(section, 'section');
    this.renderer.setProperty(section, 'innerHTML', '<h3>New Section</h3>');
    // adding section
    this.renderer.appendChild(this.fp_directive.nativeElement, section);

    this.fullpage_api.build();
  }

  removeLast() {
    const lastSection = this.fp_directive.nativeElement.lastChild;

    if (lastSection.isEqualNode(this.fullpage_api.getActiveSection().item)) {
      this.fullpage_api.moveSectionUp();
    }
    lastSection.remove();

    this.fullpage_api.build();
  }

  randomColor() {
    return '#' + Math.random().toString(16).slice(-3);
  }

  serviceOneOpen() {
    this.fullpage_api.setAutoScrolling(false);
    this.serviceOne = !this.serviceOne;
	this.serviceTwo=this.serviceThree=this.serviceFour=this.serviceFive=this.serviceSix=false;
  }
  serviceOneClose() {
    this.fullpage_api.setAutoScrolling(true);
    this.serviceOne = !this.serviceOne;
  }
  
   serviceTwoOpen() {
    this.fullpage_api.setAutoScrolling(false);
    this.serviceTwo = !this.serviceTwo;
		this.serviceOne=this.serviceThree=this.serviceFour=this.serviceFive=this.serviceSix=false;
  }
  serviceTwoClose() {
    this.fullpage_api.setAutoScrolling(true);
    this.serviceTwo = !this.serviceTwo;
  }
  
  
  
  serviceThreeOpen() {
    this.fullpage_api.setAutoScrolling(false);
    this.serviceThree = !this.serviceThree;
		this.serviceTwo=this.serviceOne=this.serviceFour=this.serviceFive=this.serviceSix=false;
  }
  serviceThreeClose() {
    this.fullpage_api.setAutoScrolling(true);
    this.serviceThree = !this.serviceThree;
  }
  
  
    serviceFourOpen() {
    this.fullpage_api.setAutoScrolling(false);
    this.serviceFour = !this.serviceFour;
		this.serviceTwo=this.serviceThree=this.serviceOne=this.serviceFive=this.serviceSix=false;
  }
  serviceFourClose() {
    this.fullpage_api.setAutoScrolling(true);
    this.serviceFour = !this.serviceFour;
  }
  
  
     serviceFiveOpen() {
    this.fullpage_api.setAutoScrolling(false);
    this.serviceFive = !this.serviceFive;
		this.serviceTwo=this.serviceThree=this.serviceFour=this.serviceOne=this.serviceSix=false;
  }
  serviceFiveClose() {
    this.fullpage_api.setAutoScrolling(true);
    this.serviceFive = !this.serviceFive;
  }
  
     serviceSixOpen() {
    this.fullpage_api.setAutoScrolling(false);
    this.serviceSix = !this.serviceSix;
		this.serviceTwo=this.serviceThree=this.serviceFour=this.serviceFive=this.serviceOne=false;
  }
  serviceSixClose() {
    this.fullpage_api.setAutoScrolling(true);
    this.serviceSix = !this.serviceSix;
  }

}
