import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { RegisterOnComponent } from './register-on/register-on.component';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { PriceComponent } from './price/price.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

const routes: Routes = [
  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: 'services', component: ServicesComponent},
  { path: 'register', component: RegisterComponent},
  { path: 'register-on', component: RegisterOnComponent},
  { path: 'login', component: LoginComponent},
  { path: 'lost-password', component: LostPasswordComponent},
  { path: 'price', component: PriceComponent},
  { path: 'enquiry', component: EnquiryComponent},
  { path: 'download-app', component: HomeComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'refund-policy', component: RefundPolicyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
