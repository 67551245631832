import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, Routes, RouterModule } from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
export interface DialogData {
  domain: string;
  name: string;
}

@Component({
  selector: 'app-register-on',
  templateUrl: './register-on.component.html',
})
export class RegisterOnComponent implements OnInit {
  mobilenumber: string;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  url: any;
  stepperIndex = 0;
  isOK = false;
  errormsg = '';
  isDisabled = true;
 isShow=false;
  // dialog 

  domain: string;
  name: string;


  // tslint:disable-next-line:variable-name
  constructor(private _formBuilder: FormBuilder, private sharedService: SharedService, private router: Router, public dialog: MatDialog,private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    if (this.sharedService.mobilenumber != "") {
      this.mobilenumber = this.sharedService.mobilenumber;
	 
    } else {
       this.router.navigateByUrl('/register');
    }
  }


  validateEmail(emailid,pass,repass) {
  if(pass==repass){

    return fetch('http://app.rdisl.com/Data.aspx/CheckEmail', {
      credentials: 'same-origin', // 'include', default: 'omit'
      method: 'POST', // 'GET', 'PUT', 'DELETE', etc.
      body: JSON.stringify({ email: emailid }), // Coordinate the body type with 'Content-Type'
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
    })
      .then(response => response.json())
      .then(data => {
        var a = JSON.parse(data.d);

        if (a.code == 0) {
          this.stepperIndex = 1;
          this.isOK = false;
          this.errormsg = '';
        }
        else {
          this.isOK = true;
          this.errormsg = a.msg;
		   this.snackBar.open(a.msg, 'Error', {
      duration: 2000
    });
        }


      })
      .catch(error => console.error(error));
	  }
	  else{
	     this.snackBar.open('Missmatched password', 'Error', {
      duration: 2000
    });
	  }


  }
  validatePersonal(fname, lname, instname) {

    if (fname != "" && instname != "") {
      this.stepperIndex = 2;
    }
	else{
		   this.snackBar.open('Please enter your personal details', 'Error', {
      duration: 2000
    });
	}

  }

  validateDomain(dname) {

    return fetch('http://app.rdisl.com/Data.aspx/CheckDomain', {
      credentials: 'same-origin', // 'include', default: 'omit'
      method: 'POST', // 'GET', 'PUT', 'DELETE', etc.
      body: JSON.stringify({ domain: dname }), // Coordinate the body type with 'Content-Type'
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
    })
      .then(response => response.json())
      .then(data => {
        let a = JSON.parse(data.d);

        if (a.code == 0) {
          this.isDisabled = false;
		     this.snackBar.open('Sub domain is available for you', '', {
      duration: 2000
    });

        } else {
          this.isOK = true;
          this.isDisabled = true;
          this.errormsg = a.msg;
		     this.snackBar.open(a.msg, 'Error', {
      duration: 2000
    });
        }

      })
      .catch(error => console.error(error));

  }


  sendData(email, pass, fname, lname, instname, dname) {

  this.isDisabled = true;
 this.isShow = true;
    return fetch('http://app.rdisl.com/Data.aspx/SendData', {
      credentials: 'same-origin', // 'include', default: 'omit'
      method: 'POST', // 'GET', 'PUT', 'DELETE', etc.
      body: JSON.stringify({ email: email,password:pass,firstName:fname,lastName:lname,instituteName:instname,domain:dname,mobile:this.sharedService.mobilenumber }), // Coordinate the body type with 'Content-Type'
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
    })
      .then(response => response.json())
      .then(data => {
        var a = JSON.parse(data.d);

        if (a.code == 0) {
         
		  this.isDisabled = true;
		  this.sharedService.domainname=dname;
		   this.isShow = false;
		  this.openDialog();
        }
        else {
			  this.isDisabled = false;
			   this.isShow = false;
          this.isOK = true;
          this.errormsg = a.msg;
		       this.snackBar.open(a.msg, 'Error', {
      duration: 2000
    });
        }

      })
      .catch(error => console.error(error));
  }

  // onSelectFile(event) {
  //   if (event.target.files && event.target.files[0]) {
  //     const reader = new FileReader();

  //     reader.readAsDataURL(event.target.files[0]); // read file as data url

  //     // tslint:disable-next-line:no-shadowed-variable
  //     reader.onload = (event) => { // called once readAsDataURL is completed
  //       this.url = event.target; // event.target.result;
  //     };
  //   }
  // }
  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event: any) => {
        this.url = event.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  openDialog(): void {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open( DialogComponent, {
      width: '300px',
	
      data: {name: this.name, domain: this.sharedService.domainname}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      
	   
    window.location.href = 'http://'+this.sharedService.domainname+'.onlinetestserver.com/';
    });
  }

}

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
})
export class DialogComponent {

  constructor(private sharedService: SharedService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

	
  onNoClick(): void {
    this.dialogRef.close();
	
  }

}


