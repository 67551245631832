import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared/shared.service';
// import {MatInputModule} from '@angular/material/input';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  logoText: string;
  hide = true;
   isOK=false;
  errormsg='';
  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    this.logoText = this.sharedService.logoText;
  }

   getLogin(emailid) {
 

   return fetch('http://app.rdisl.com/Data.aspx/GetLoginPage', {
    credentials: 'same-origin', // 'include', default: 'omit'
    method: 'POST', // 'GET', 'PUT', 'DELETE', etc.
    body: JSON.stringify({Email: emailid}), // Coordinate the body type with 'Content-Type'
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
  })
  .then(response => response.json())
  .then(data => {
  var a=JSON.parse(data.d);

  if(a.code==1){

   this.isOK=true;
 // this.errormsg=a.msg;
 window.location.href = 'http://'+a.msg+'.onlinetestserver.com/adminlogin/Login.aspx?ref='+emailid;

  }else{
  this.isOK=true;
  this.errormsg=a.msg;
  }

})
.catch(error => {console.error(error)
 this.isOK=true;
  this.errormsg=error;
  }
);

}
}
