import { Component, OnInit, HostListener, Inject, ViewEncapsulation } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { SharedService } from '../shared/shared.service';
import { FormControl } from '@angular/forms';

export enum AllLang {
  English = 'English',
  हिंदी = 'Hindi',
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  animations: [
    trigger('fade',
      [
        state('void', style({ opacity: 0 })),
        transition(':enter', [animate(300)]),
        transition(':leave', [animate(500)]),
      ]
    )],
  // Encapsulation has to be disabled in order for the
  // component style to apply to the select panel.
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  allLang = AllLang;
  selectedLang: AllLang = AllLang.English;
  langOptions = Object.keys(AllLang);
  logoText: string;
  isOpen = false;
  public isEng = true;
  public localBool = false;

  constructor(@Inject(DOCUMENT) document,
              private sharedService: SharedService) { }

  ngOnInit() {
    this.logoText = this.sharedService.logoText;
    // this.sharedService.isEng = this.isEng;
    /// this.sharedService.currentLang.subscribe(lang => this.isEng = this.isEng);

    ////
    // this.sharedService.menuActive.subscribe(value => this.localBool = value);
    this.sharedService.currentLang.subscribe(x => this.isEng = x);

  }


  toggleLang() {
    if (this.selectedLang === 'English') {
      this.isEng = true;
      this.sharedService.onChangeLang(this.isEng);
    } else {
      this.isEng = false;
      this.sharedService.onChangeLang(this.isEng);
    }
 }

    /////
  //   ComponentToggleMenu() {
  //     if (this.selectedLang === 'English') {
  //       this.localBool = true;
  //       this.sharedService.toggleMenu(this.localBool);
  //     } else {
  //       this.localBool = false;
  //       this.sharedService.toggleMenu(this.localBool);
  //     }
  //  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 30) {
      // tslint:disable-next-line:prefer-const
      let element = document.getElementById('navbar');
      element.classList.add('sticky');
    } else {
      // tslint:disable-next-line:prefer-const
      let element = document.getElementById('navbar');
      element.classList.remove('sticky');
    }
  }
}
