import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Router, Routes, RouterModule } from '@angular/router';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
  logoText: string;
  isOTP = false;
  isOK = false;
  errormsg = '';
  mobileno = '';
  firstFormGroup: FormGroup;
 isDisabled = false;
  // tslint:disable-next-line:variable-name
  constructor(private sharedService: SharedService, private _formBuilder: FormBuilder, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.logoText = this.sharedService.logoText;
  }

  sendOTP(number: string) {

    if (number != "") {
 this.isDisabled = true;
      return fetch('http://app.rdisl.com/Data.aspx/SendOTP', {
        credentials: 'same-origin', // 'include', default: 'omit'
        method: 'POST', // 'GET', 'PUT', 'DELETE', etc.
        body: JSON.stringify({ mobile: number }), // Coordinate the body type with 'Content-Type'
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
      })
        .then(response => response.json())
        .then(data => {
          var a = JSON.parse(data.d);

          if (a.code == 0) {
            this.sharedService.mobilenumber = number;
            this.isOK = false;
            this.isOTP = true;
			 this.isDisabled = false;
            this.errormsg = a.msg;
            this.mobileno = number;
            this.snackBar.open('OTP sent successfully on your entered mobile number', '', {
              duration: 2000
            });
          } else {
            this.isOK = true;
			 this.isDisabled = false;
            this.errormsg = a.msg;
            this.snackBar.open(a.msg, 'Error', {
              duration: 2000
            });
          }

        })
        .catch(error => {
          console.error(error)
          this.isOK = true;
		   this.isDisabled = false;
          this.errormsg = error;
          this.snackBar.open(error, 'Error', {
            duration: 2000
          });
        }
        );
    }
    else {
      this.isOK = true;
	   this.isDisabled = false;
      this.errormsg = ''; //'Please enter mobile number';
      this.snackBar.open('Please enter mobile number', 'Error', {
        duration: 2000
      });
    }

  }


  resendOTP(number: string) {
this.isDisabled = true;
    return fetch('http://app.rdisl.com/Data.aspx/SendOTP', {
      credentials: 'same-origin', // 'include', default: 'omit'
      method: 'POST', // 'GET', 'PUT', 'DELETE', etc.
      body: JSON.stringify({ mobile: number }), // Coordinate the body type with 'Content-Type'
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
    })
      .then(response => response.json())
      .then(data => {
        var a = JSON.parse(data.d);

        if (a.code == 0) {
          this.sharedService.mobilenumber = number;
          this.isOK = true;
          this.isDisabled = false;
          this.errormsg = 'OTP Message has been resend successfully';
          this.mobileno = number;
          this.snackBar.open('OTP Message has been resend successfully', '', {
            duration: 2000
          });
        } else {
          this.isOK = true;
		  this.isDisabled = false;
          this.errormsg = a.msg;
          this.snackBar.open(a.msg, 'Error', {
            duration: 2000
          });
        }

      })
      .catch(error => {
        console.error(error)
        this.isOK = true;
        this.errormsg = error;
		this.isDisabled = false;
        this.snackBar.open(error, 'Error', {
          duration: 2000
        });
      }
      );
  }


  validateOTP(otp: string) {

    return fetch('http://app.rdisl.com/Data.aspx/GetOTP', {
      credentials: 'same-origin', // 'include', default: 'omit'
      method: 'POST', // 'GET', 'PUT', 'DELETE', etc.
      body: JSON.stringify({ mobile: this.mobileno, otpcode: otp }), // Coordinate the body type with 'Content-Type'
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
    })
      .then(response => response.json())
      .then(data => {
        var a = JSON.parse(data.d);

        if (a.code == otp) {
          this.snackBar.open('OTP Matched Successfully', '', {
            duration: 2000
          });
          this.router.navigateByUrl('/register-on');
        } else {
          this.isOK = true;
          this.errormsg = a.msg;
          this.snackBar.open(a.msg, 'Error', {
            duration: 2000
          });
        }

      })
      .catch(error => {
        console.error(error);
        this.isOK = true;
        this.errormsg = error;
        this.snackBar.open(error, 'Error', {
          duration: 2000
        });
      }
      );
  }
}
