import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
})
export class LostPasswordComponent implements OnInit {
 isOK=false;
  errormsg='';

  constructor() { }

  ngOnInit() {
  }


  
   forgotPassword(emailid:string) {
 

   return fetch('http://app.rdisl.com/Data.aspx/ForgotPassword', {
    credentials: 'same-origin', // 'include', default: 'omit'
    method: 'POST', // 'GET', 'PUT', 'DELETE', etc.
    body: JSON.stringify({email: emailid}), // Coordinate the body type with 'Content-Type'
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
  })
  .then(response => response.json())
  .then(data => {
  var a=JSON.parse(data.d);

  if(a.code==0){

   this.isOK=true;
  this.errormsg=a.msg;

  }else{
  this.isOK=true;
  this.errormsg=a.msg;
  }

})
.catch(error => {console.error(error)
 this.isOK=true;
  this.errormsg=error;
  }
);

}

}
