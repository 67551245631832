import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {
  isEng: boolean;
  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    this.isEng = this.sharedService.isEng;
  }

}
