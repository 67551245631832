import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
})
export class MobileAppComponent implements OnInit {
  isEng: boolean;

  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    this.sharedService.currentLang.subscribe(x => this.isEng = x);
  }

}
